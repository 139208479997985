
import {computed, defineComponent, onMounted, ref} from "vue";
import Entities from "../../components/base/common/Entities.vue";
import {useTaskStore} from "@/store/task.store";
import {GetSpaceId, LoadPanel, PinaFilterObjects} from "@/core/composite/composite";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import UserListSelect from "@/components/common/UserListSelect.vue";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TaskName from "@/views/task/TaskName.vue";
import TaskDueDate from "@/views/work/TaskDueDate.vue";
import TaskCompleteStatus from "@/views/task/TaskCompleteStatus.vue";
import UserLink from "@/views/user/UserLink.vue";

export default defineComponent({
  name: "Tasks",
  components: {
    UserLink,
    TaskCompleteStatus, TaskDueDate, TaskName, UserListSelect, DateTimeFormat, KtDatatable, Entities},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("My Team Work Tasks",
        [
          {link: false, router: '', text: 'My Team Work Tasks'}
        ]
      )
    })
    const filterObjects = ref({
      isDelegateTask: false,
      assignedId: '',
      myTeam: true,
      completed: false,
      overdue: false,
      noComplete: true
    })
    const taskStore = useTaskStore();
    const page = computed(() => taskStore.page);
    const headers = ref<any>([])
    const buildHeaders = (isCompleted: boolean) => {
      if (isCompleted) {
        headers.value = [
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Status',
            key: 'status',
            sortable: false,
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
          {
            name: 'Completed',
            key: 'completedDt',
            sortable: false
          },
        ]
      } else {
        headers.value = [
          {
            name: 'Name',
            key: 'name',
            sortable: false
          },
          {
            name: 'Status',
            key: 'status',
            sortable: false,
          },
          {
            name: 'Assigned',
            key: 'assigned',
            sortable: false
          },
          {
            name: 'Due Date',
            key: 'dueDate',
            sortable: false
          },
        ]
      }
    }
    buildHeaders(false);
    return {
      buildHeaders,
      headers,
      filterObjects,
      page,
      ...PinaFilterObjects(taskStore, filterObjects.value, ['owner'], 'sort:dueDate:asc'),
      taskStore,
      ...LoadPanel(),
      ...GetSpaceId(),
    }
  },
  methods: {
    searchEvent() {
      this.filterObjects.noComplete = !this.filterObjects.completed;
      if (this.filterObjects.completed) {
        this.buildHeaders(true);
        this.updateSort('sort:dueDate:desc')
      }else {
        this.buildHeaders(false);
          this.updateSort('sort:dueDate:asc')
      }
      this.updateFilterObject(this.filterObjects);
    },
    discardEvent() {
      this.updateSort('sort:dueDate:asc')
      this.filterObjects = {
        assignedId: '',
        isDelegateTask: false,
        myTeam: true,
        completed: false,
        overdue: false,
        noComplete: true
      }
      this.buildHeaders(false);
      this.updateFilterObject(this.filterObjects);
    },
  }
})
